import React from "react";
import {debug} from "../services/Debugger";

// className concat
export function cc(dominantClassName, ...className) {
    return [...className, dominantClassName].join(" ");
}

export function Heading({className, children}) {
    return <div className={cc("text-2xl font-medium mt-2 py-3", className)}>{children}</div>;
}

export function Subheading({className, children}) {
    return <div className={cc("text-xl font-medium mt-1 py-2", className)}>{children}</div>;
}

export function Paragraph({className, children}) {
    return <div className={cc("mb-0.5 py-1", className)}>{children}</div>;
}

export function Quote({className, children}) {
    return <div className={cc("italic border-l-4 border-soc-grey pl-6 mb-0.5 py-1", className)}>{children}</div>;
}

export function Emphasize({className, children}) {
    return <span className={cc("font-bold", className)}>{children}</span>;
}

export function Italicize({className, children}) {
    return <span className={cc("italic", className)}>{children}</span>;
}

export function List({className, ordered = false, children}) {
    if (ordered) return <ol className={cc("list-decimal mb-0.5 py-1", className)}>{wrapChildren(children)}</ol>;
    return <ul className={cc("list-disc mb-0.5 py-1", className)}>{wrapChildren(children)}</ul>;

    function wrapChildren(children) {
        return children.map((child, index) => {
            if (child.type === 'li' || child.type?.name === 'Item') return child;
            return <Item key={child.key || `item-${index}`}>{child}</Item>;
        })
    }
}

export function Item({className, children}) {
    return <li className={cc("ml-8", className)}>{children}</li>
}

export function Input(props) {
    const {onChange, className, ...remainingProps} = props;

    return <input onChange={e => onChange(e.target.value)}
                  className={cc("bg-soc-face rounded p-2", className)}
                  {...remainingProps} />
}
