import React from "react";

export function ModalDialog({title, children}) {
    return <div className="fixed inset-0 bg-white bg-opacity-75 z-50 grid grid-cols-1 place-items-center">
        <div className="max-w-screen-md bg-gray-200 shadow-lg border-soc-cheeks-dark border">
            <div className="px-4 py-1 bg-soc-cheeks-dark text-white font-bold">{title}</div>
            <div className="p-4">
                {children}
            </div>
        </div>
    </div>
}