import React, {useEffect, useState} from "react";
import {gql, useApolloClient, useMutation, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {BehaviourPollWithoutSubmission} from "../components/BehaviourPoll";
import {useBlocker, useParams} from "react-router-dom";
import {allAssessmentFields} from "../components/AssessmentDetails";
import {debug} from "../services/Debugger";
import {buttonStyle} from "../utils/styles";
import {cc} from "../components/Typography";
import {ModalDialog} from "../components/ModalDialog";
import {allBehaviourObservationFields} from "../components/BehaviourObservation";
import {forgetAssessmentResults} from "../services/CachedAssessmentResults";

const GET = gql`
    ${allAssessmentFields}
    query getAssessment($assessmentId: ID!) {
        assessment(id: $assessmentId) {
            ...AllAssessmentFields
        }
    }
`;

const createBehaviourObservations = gql`
    ${allBehaviourObservationFields}
    mutation CreateBehaviourObservations($input: [BehaviourObservationInput!]!) {
        createBehaviourObservations(observations: $input) {
            ...AllBehaviourObservationFields
        }
    }
`;

export function AssessmentSurveyRoute() {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET, {variables: {assessmentId: id}});

    const [polls, setPolls] = useState({});
    const [savedPolls, setSavedPolls] = useState({});
    const [dirty, setDirty] = useState(false);

    const client = useApolloClient();
    const [createObservations, {data: mutationData, loading: mutationLoading, error: mutationError, reset}] = useMutation(createBehaviourObservations);

    useEffect(() => {
        if (!dirty) return;

        function handleBeforeUnload(event) {
            debug("Handling unload with event", event);
            event?.preventDefault();
            return (event.returnValue = "");
        }

        window.addEventListener('beforeunload', handleBeforeUnload, {capture: true});
        return () => window.removeEventListener('beforeunload', handleBeforeUnload, {capture: true});
    }, [dirty]);


    function recordPoll(behaviourId, answer) {
        debug("Recording poll", behaviourId, answer);

        polls[behaviourId] = answer;
        setPolls(polls);
        setDirty(true);
    }

    function updateBehaviourObservationList(cache, existing, observations) {
        debug("Updating cache: adding observations to behaviourObservationList", observations);
        return [
            ...existing,
            ...observations.map(observation => ({
                    ...observation,
                    behaviour: {__ref: cache.identify(observation.behaviour)}
                })
            )
        ];
    }

    function savePolls() {
        debug("Saving polls", polls);

        createObservations({
            variables: {input: Object.keys(polls).map(id => ({id: id, isPresent: polls[id]}))},
            update(cache, {data: {createBehaviourObservations}}) {
                forgetAssessmentResults(client);
                cache.modify({
                    fields: {
                        behaviourObservationList(existing = []) {
                            return updateBehaviourObservationList(cache, existing, createBehaviourObservations);
                        }
                    }
                })
            }
        })
            .then(() => {
                setSavedPolls({...savedPolls, ...polls});
                setPolls({});
                setDirty(false);
            });
    }

    let blocker = useBlocker(({currentLocation, nextLocation}) => dirty && currentLocation.pathname !== nextLocation.pathname);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    return <>
        {blocker.state === "blocked" && <ModalDialog>You have not saved all your assessment results.
            If you continue to navigate away, you will loose those changes.
            <div className="mt-4">
                <button onClick={() => blocker.reset()} className={cc("mr-1", buttonStyle().className)}>Cancel</button>
                <button onClick={() => blocker.proceed()} className={cc("ml-1", buttonStyle().className)}>Continue</button>
            </div>
        </ModalDialog>}

        <div>
            <RunSurvey polls={createPollsContainer(data.assessment.behaviourList)} recordPoll={recordPoll} />
            <button onClick={savePolls} disabled={!dirty} className={cc("mt-8 w-full", buttonStyle({bg: "bg-soc-grey enabled:hover:bg-soc-cheeks-dark"}).className)}>
                {mutationLoading ? "Submitting" : "Submit your answers" }
            </button>

            {error && <div className="bg-red-200">Submission error! {error.message}</div>}
        </div>
    </>;

    function createPollsContainer(behaviourList) {
        const container = {};

        behaviourList.forEach(behaviour => container[behaviour.id] = {behaviour: behaviour, result: polls[behaviour.id] || savedPolls[behaviour.id]});
        return container;
    }
}

function RunSurvey({polls, recordPoll}) {
    if (!Object.keys(polls).length)
        return <div>
            No challenges defined for this assessment.
        </div>;

    return <div className="w-full">
        {Object.keys(polls).map(id => <BehaviourPollWithoutSubmission key={id} behaviour={polls[id].behaviour} poll={polls[id].result} className="mt-4" updatePoll={result => recordPoll(id, result)}/>)}
    </div>

}
