import {useMutation, useQuery, gql, useApolloClient} from "@apollo/client";
import {GET_ASSESSMENT_RESULT_BY_ID} from "../routes/AssessmentResult.route";
import {Heading, Paragraph} from "./Typography";
import React, {useState} from "react";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {debug} from "../services/Debugger";
import {ProgressBar} from "./ProgressBar";
import {BehaviourPoll} from "./BehaviourPoll";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck} from "@fortawesome/pro-solid-svg-icons";
import {faBadgeCheck as faBadgeCheckRegular} from "@fortawesome/pro-regular-svg-icons";
import {rememberAssessmentResults} from "../services/CachedAssessmentResults";

export const ONBOARDING_ASSESSMENT_ID = process.env.REACT_APP_ONBOARDING_ASSESSMENT;

export const allUserOnboardingFields = gql`
    fragment AllUserOnboardingFields on UserOnboarding {
        completedAt
        followedUpAt
    }
`;

export const completeOnboardingMutation = gql`
    ${allUserOnboardingFields}
    mutation CompleteOnboarding {
        completeUserOnboarding {
            ...AllUserOnboardingFields
        }
    }
`;

export function OnboardingAssessment({children}) {
    const client = useApolloClient();

    const {
        loading,
        error,
        data
    } = useQuery(GET_ASSESSMENT_RESULT_BY_ID, {variables: {assessmentId: ONBOARDING_ASSESSMENT_ID}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    rememberAssessmentResults(client, data.assessmentResult);

    return <RunAssessmentOneQuestionAtATime assessmentResult={data.assessmentResult}>{children}</RunAssessmentOneQuestionAtATime>;
}

function RunAssessmentOneQuestionAtATime({assessmentResult, children}) {
    const [completeOnboarding, {loading, error}] = useMutation(completeOnboardingMutation);
    const [[behavioursToAssess, observations], updateAssessment] = useState(calculateOutstandingPolls(assessmentResult));
    const assessmentSize = assessmentResult.assessment.behaviourList.length;

    function recordPoll(behaviour, isPresent) {
        debug("Recording poll", behaviour, isPresent);
        // Todo: save all polls including completion of onboarding into single mutation
        const remainingPolls = behavioursToAssess.slice(1);
        if (!remainingPolls.length) {
            completeOnboarding()
                .then(() => {debug("Completed onboarding")});
        }
        updateAssessment([remainingPolls, [...observations, {behaviour, isPresent}]]);
    }

    if (loading) return 'Submitting';
    if (error) return `Submission error! ${error.message}`;

    if (!behavioursToAssess.length) {
        return <>
            <GetAssessmentResult result={assessmentResult} />
            {children}
        </>;
    }

    return <>
        <Heading>Let's get started!</Heading>
        <Paragraph>Help us get to know your team a little bit better, so we can help it improve.</Paragraph>
        <Paragraph>This process will guide you through {assessmentSize} statements. Let us know if they apply to your team or not.</Paragraph>

        <BehaviourPoll behaviour={behavioursToAssess[0]} callback={recordPoll} resetAfterPoll />

        <ProgressBar className="w-full mt-4" min={0} max={assessmentSize} actual={observations.length}
                     showValue={false} height="h-1"/>
    </>;
}

function GetAssessmentResult({result}) {
    return <div className="my-4 border-gray-100 border-2 rounded-md p-4 flex">
        <Paragraph className="relative flex mr-4">
            <FontAwesomeIcon icon={faBadgeCheck} className="animate-ping absolute inline-flex opacity-60 text-green-600 text-xl"/>
            <FontAwesomeIcon icon={faBadgeCheckRegular} className="relative inline-flex text-green-600 text-xl"/>
        </Paragraph>
        <Paragraph>
            Check out <Link className="text-soc-cheeks-dark hover:underline"
                            to={`/assessments/${result.assessment.id}/result`}>your initial assessment</Link>.
            We will be in touch shortly to schedule your coaching call.
        </Paragraph>
    </div>;
}

export function calculateOutstandingPolls(assessmentResult) {
    const observations = assessmentResult.current?.observationList || [];
    const behavioursToAssess = assessmentResult.assessment.behaviourList.filter(b => !observations.find(o => ofBehaviour(o, b)));

    return [behavioursToAssess, observations];

    function ofBehaviour(observation, behaviour) {
        return observation.behaviour.id === behaviour.id;
    }
}

