import {createContext, useContext, useEffect, useState} from "react";
import {debug} from "./Debugger";

export const Features = createContext([]);

export function LoadFeaturesFromUrl({children}) {
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setFeatures(params?.getAll("F-ON") || []);

    }, []);

    debug("Enabling features", features);

    return <Features.Provider value={features}>
        {children}
    </Features.Provider>
}

export function Enabled({feature, children}) {
    debug("Querying feature enabled", feature);
    const features = useContext(Features);
    if (features.includes(feature)) return children;
}

export function Disabled({feature, children}) {
    debug("Querying feature disabled", feature);
    const features = useContext(Features);
    if (!features.includes(feature)) return children;
}