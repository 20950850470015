import {cc, Emphasize, Heading, Paragraph} from "./Typography";
import React from "react";
import {Logo} from "./Logo";
import {Link} from "react-router-dom";

export function WelcomeSection({className}) {
    return <div className={cc(className, "flex flex-col md:flex-row items-center")}>
        <Logo className="h-40 md:h-80 place-content-center md:mr-8"/>
        <div>
            <WelcomeToSocratesAdvice />

            <Paragraph>
                This makes otherwise <Emphasize>expensive coaching services</Emphasize> affordable for all teams.
            </Paragraph>
        </div>
    </div>
}

export function WelcomeToSocratesAdvice() {
    return <>
        <Heading>Welcome to <Emphasize>Socrates Advice</Emphasize>, your trusted guide on your way to
            greatness!</Heading>
        <Paragraph>
            Socrates Advice assists teams in <Emphasize>achieving delivery success</Emphasize> by observing, analysing and improving team behaviours and habits.
        </Paragraph>
    </>;
}