import {createBrowserRouter, Outlet, useRouteError} from "react-router-dom";
import {App} from "./App";
import {Error} from "./components/Error";
import {HomeRoute} from "./routes/Home.route";
import {ProfileRoute} from "./routes/Profile.route";
import {Model} from "./components/Model";
import {Center} from "./components/Center";
import {AuthContext} from "react-oauth2-code-pkce";
import {useContext} from "react";
import {PollsRoute} from "./routes/Polls.route";
import {AssessmentSurveyRoute} from "./routes/AssessmentSurvey.route";
import {AssessmentResultForUserRoute, AssessmentResultRoute} from "./routes/AssessmentResult.route";
import {AssessmentRoute} from "./routes/Assessment.route";
import {LoginButton} from "./components/Authentication";
import {ImprovementCalculatorRoute} from "./routes/ImprovementCalculator.route";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicy.page";
import {AdminRoute} from "./routes/Admin.route";
import {isAuthorized} from "./components/Authorization.helper";

export const routerConfig = createBrowserRouter([{
    path: "/",
    element: <App />,
    errorElement: <App><Center><RouteError/></Center></App>,
    children: [
      { index: true, element: <HomeRoute/> },
      {
        element: <ProtectedRoute/>,
        errorElement: <Center><RouteError/></Center>,
        children: [
          { path: "profile", element: <ProfileRoute/> },
          { path: "polls", element: <PollsRoute/> },
          { path: "assessments", element: <AssessmentRoute/> },
          { path: "assessments/:id/survey", element: <AssessmentSurveyRoute/> },
          { path: "assessments/:id/result", element: <AssessmentResultRoute/> },
          { path: "tools/improvement-calculator", element: <ImprovementCalculatorRoute/> },
          { path: "privacy-policy", element: <PrivacyPolicyPage/> },
        ]
      },
      {
        element: <ProtectedRoute role="admin"/>,
        errorElement: <Center><RouteError/></Center>,
        children: [
          { path: "admin", element: <AdminRoute/> },
          { path: "admin/model", element: <Model/> },
          { path: "admin/as/:user/assessments/:id/result", element: <AssessmentResultForUserRoute/> },
        ]
      }
    ]
  }]);

function ProtectedRoute({role}) {
  const authContext = useContext(AuthContext);

  if (authContext.idTokenData) {
    if (role) {
      if (isAuthorized(authContext, role))
        return <Outlet />
      else return <div>
        <div>This page is protected and requires {role} access.</div>
      </div>
    }
    return <Outlet/>;
  }

  return <div>
    <div>This page is protected, please login.</div>
    <LoginButton>Login</LoginButton>
  </div>;
}

function RouteError() {
  const error = useRouteError();
  return <Error status={error.status}>{error.statusText || error.message}</Error>;
}
