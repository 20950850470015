import React from 'react';
import {cc, Emphasize, Heading, Input, Paragraph, Subheading} from "../components/Typography";
import {toReadableNumber} from "../utils/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-regular-svg-icons";
import {Carousel} from "../components/Carousel";
import {LoginButton} from "../components/Authentication";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";

export function ImprovementCalculatorRoute({className, compact}) {
    const [dailyGainInSeconds, setDailyGainInSeconds] = React.useState(30);
    const [dailyInvestmentInMinutes, setDailyInvestmentInMinutes] = React.useState(15);
    const [workingHoursPerDay, setWorkingHoursPerDay] = React.useState(8);
    const [workingDaysPerYear, setWorkingDaysPerYear] = React.useState(240);
    const [averageSalary, setAverageSalary] = React.useState(60000);
    const [teamSize, setTeamSize] = React.useState(1);

    return <div className={className}>
        <Heading>How much is your improvement worth?</Heading>
        <Paragraph>Continuous improvement is sold as a lovely story, but does it really pay off? After all, investing
            time to improve on a daily basis takes precious time away from delivering new features.</Paragraph>
        <Paragraph>Stop debating the value of continuous improvement and use our improvement calculator to make your own
            calls.</Paragraph>

        <Subheading>Make it yours!</Subheading>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 border-2 border-black rounded-md">
            <div>
                <Variable name="Daily improvement" setter={setDailyGainInSeconds} value={dailyGainInSeconds} size="3"
                          unit="seconds">
                    Every day, you will improve so that - when you completed as much work as the day before - you still
                    have time left to spend on something else.
                </Variable>

                <Variable name="Daily investment" setter={setDailyInvestmentInMinutes} value={dailyInvestmentInMinutes}
                          size="3" unit="minutes">
                    Every day, you will invest this much time in improving. You will reap the benefits of your
                    investment the following day.
                </Variable>

                {compact && <Paragraph className="text-sm italic"><FontAwesomeIcon icon={faCircleInfo}/> The metrics are calculated for a single team member based on an 8 hour workday and 240 working days a year.
                    Login for a more elaborate calculator with more control and insights.
                </Paragraph>}

                {!compact && <>
                    <Variable name="Average salary" setter={setAverageSalary} value={averageSalary} size="10" unit="$">
                        Average annual salary for a team member.
                    </Variable>

                    <Variable name="Working hours" setter={setWorkingHoursPerDay} value={workingHoursPerDay}
                              size="3" unit="hours/day">
                    </Variable>

                    <Variable name="Working days" setter={setWorkingDaysPerYear} value={workingDaysPerYear}
                              size="3" unit="days/year">
                        The industry average for a full-time employee is 240 working days
                    </Variable>

                    <Variable name="Team size" setter={setTeamSize} value={teamSize}
                              size="3" unit="team member(s)">
                    </Variable>
                </>}

            </div>

            <Metrics compact={compact} dailyGain={dailyGainInSeconds} dailyInvestment={dailyInvestmentInMinutes * 60}
                     workingHours={workingHoursPerDay} workingDays={workingDaysPerYear} averageSalary={averageSalary}
                     teamSize={teamSize}/>
        </div>
    </div>;
}

function Variable({name, value, unit, setter, children, size}) {
    return <Paragraph>
        {name}: <Input onChange={setter} value={value} size={size} className="text-right"/> {unit}
        {children && <div className="text-xs">{children}</div>}
    </Paragraph>
}

function Metrics({compact, dailyGain, dailyInvestment, workingHours, workingDays, averageSalary, teamSize}) {
    const gainAfterOneYear = timeFromSeconds(calculateGainOnDay(dailyGain, workingDays), workingHours);
    const productivityAfterOneYear = calculateProductivityOnDay(workingHours * 3600, dailyGain, dailyInvestment, workingDays);
    const roiAfterOneYear = timeFromSeconds((productivityAfterOneYear - 1) * 3600 * workingHours * workingDays * teamSize, workingHours);

    return <div className="grid grid-cols-1 md:grid-cols-2 items-stretch gap-2">
        <Metric value={calculateBreakEvenDay(dailyGain, dailyInvestment)} unit="days">
            until you recovered what you invested, only net gains from here on
        </Metric>

        <Metric value={gainAfterOneYear.value} unit={gainAfterOneYear.unit}>
            extra <Emphasize>daily</Emphasize> capacity per team member after a single working year
        </Metric>

        <Metric value={roiAfterOneYear.value} unit={roiAfterOneYear.unit}>
            freed up time during the first year
        </Metric>

        {compact && <LoginButton bgClass="bg-soc-border hover:bg-soc-border/50">
            <Metric value="?" unit="$" className="bg-transparent">
                If you want to see your cost savings, sign up
            </Metric>
        </LoginButton>}

        {!compact && <Metric value={toReadableNumber((productivityAfterOneYear - 1) * averageSalary * teamSize)} unit="$">
            saved after the first year
        </Metric>}
    </div>
}

function Metric({value, unit, className="bg-soc-cheeks", children}) {
    return <div className={cc(className, "flex flex-col items-center justify-center p-2 min-h-24")}>
        <div className="grow inline-block content-center text-center align-baseline">
            <span className="text-5xl font-bold">{toReadableNumber(value)}</span> {unit && <span className="text-sm text-nowrap">{unit}</span>}
        </div>
        <div className="text-xs text-center mx-auto">
            {children}
        </div>
    </div>;
}

function calculateGainOnDay(dailyGain, day) {
    return dailyGain * day;
}

function calculateCapacityOnDay(capacity, dailyGain, dailyInvestment, day) {
    return capacity + day * dailyGain - dailyInvestment;
}

function calculateProductivityOnDay(capacity, dailyGain, dailyInvestment, day) {
    return 1 + (day * dailyGain) / (2 * capacity) - dailyInvestment / capacity;
}

function calculateBreakEvenDay(dailyGain, dailyInvestment) {
    return 2 * dailyInvestment / dailyGain;
}

function calculateCostSavingsOnDay(dailyGain, workingHours, averageSalary, day) {
    return averageSalary * calculateGainOnDay(dailyGain, day) / (3600 * workingHours * day);
}

function timeFromSeconds(seconds, workingHours) {
    if (Math.abs(seconds) > (workingHours * 3600)) return {value: seconds / (workingHours * 3600), unit: "working days"};
    if (Math.abs(seconds) > 3600) return {value: seconds / 3600, unit: "hours"};
    if (Math.abs(seconds) > 60) return {value: seconds / 60, unit: "minutes"};
    return {value: seconds, unit: "seconds"};
}

