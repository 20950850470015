import React, {useContext} from "react";
import {gql, useApolloClient, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {allAssessmentFields, allCapabilityFields} from "../components/AssessmentDetails";
import {cc, Paragraph, Subheading} from "../components/Typography";
import {Source} from "../components/Source";
import {useNavigate} from "react-router-dom";
import {allBehaviourObservationFields} from "../components/BehaviourObservation";
import {buttonStyle} from "../utils/styles";

import {rememberAssessmentResults} from "../services/CachedAssessmentResults";
import {Disabled, Enabled, Features} from "../services/Features";
import {GET_ASSESSMENT_RESULT_BY_ID} from "./AssessmentResult.route";
import {ONBOARDING_ASSESSMENT_ID} from "../components/OnboardingAssessment";

const GET = gql`
    ${allBehaviourObservationFields}
    ${allCapabilityFields}
    ${allAssessmentFields}
    query {
        assessmentResultList {
            assessment {
                ...AllAssessmentFields
            }
            current {
                observationList {
                    ...AllBehaviourObservationFields
                }
            }
        }
    }
`;

export function AssessmentRoute() {
    return <div className="w-full">
        <Enabled feature="assessments-all-models">
            <AllAssessments/>
        </Enabled>
        <Disabled feature="assessments-all-models">
            <OnboardingAssessment/>
        </Disabled>
    </div>
}

function OnboardingAssessment() {
    const client = useApolloClient();
    const {loading, error, data} = useQuery(GET_ASSESSMENT_RESULT_BY_ID, {variables: {assessmentId: ONBOARDING_ASSESSMENT_ID}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    rememberAssessmentResults(client, data.assessmentResult);

    return <AssessmentResult key={data.assessmentResult.assessment.id} result={data.assessmentResult}
                                                                   className="mt-4"/>;
}

function AllAssessments() {
    const client = useApolloClient();
    const {loading, error, data} = useQuery(GET);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    rememberAssessmentResults(client, ...data.assessmentResultList);

    return <>
        {data.assessmentResultList.map(result => <AssessmentResult key={result.assessment.id} result={result}
                                                                   className="mt-4"/>)}
    </>
}

function AssessmentResult({result, className}) {
    const navigate = useNavigate();
    const handleView = () => navigate(`/assessments/${result.assessment.id}/result`);
    const handleSurvey = () => navigate(`/assessments/${result.assessment.id}/survey`);

    return <div className={cc(className)}>
        <Subheading>Name: {result.assessment.name}</Subheading>
        {result.assessment.description && <Paragraph>{result.assessment.description}</Paragraph>}
        <Source wrapper={s => <div className="italic text-sm text-right pt-1">source: {s}</div>}>{result.assessment.source}</Source>

        <div className="mt-1 grid grid- grid-cols-2 gap-1">
            <button onClick={handleView} disabled={result.current === null} className={buttonStyle({bg: "bg-soc-grey hover:bg-soc-cheeks-dark"}).className}>View</button>
            <button onClick={handleSurvey} className={buttonStyle({bg: "bg-soc-grey hover:bg-soc-cheeks-dark"}).className}>Survey</button>
        </div>
        <div className="w-full bg-gray-100 mt-2">
            <div className="h-1 bg-soc-cheeks" style={{width: `${getProgress()}%`}}/>
        </div>
    </div>;

    function getProgress() {
        if (!result.current) return 0;
        return 100 * result.current?.observationList?.length / result.assessment.behaviourList.length;
    }
}