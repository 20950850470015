import React from "react";
import {cc} from "./Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons";

export function Footer({className}) {
    return <footer className={cc(className, "flex-none mt-6 flex justify-between gap-x-2 text-sm")}>
        <div>
            &copy; MMXXIV Socrates Advice Inc. - version {process.env.REACT_APP_VERSION}
        </div>
        <div className="text-right hover:bold">
            <a href="mailto:feedback@socratesadvice.com?subject=SocratesAdvice%20Feedback" className="italic hover:underline">
                <FontAwesomeIcon icon={faEnvelope}/> Send us your feedback</a>
        </div>
    </footer>;
}
