import React, {useContext} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {Heading, Paragraph} from "../components/Typography";
import {Timestamp} from "../components/Timestamp";

export function ProfileRoute() {
    const {idTokenData} = useContext(AuthContext);

    return <div className="max-w-full">
        <Heading>Welcome {idTokenData?.name}</Heading>
        <Paragraph>Email: {idTokenData?.email}</Paragraph>
        <Paragraph>Logged in using {idTokenData["auth-iss"]} on <Timestamp ts={idTokenData.iat * 1000} /></Paragraph>
    </div>;
}
