import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import './index.css';
import {AuthContext, AuthProvider} from "react-oauth2-code-pkce";
import {authConfig} from "./authConfig";
import {RouterProvider} from "react-router-dom";
import {routerConfig} from "./routerConfig";
import {ApolloProvider} from "@apollo/client";
import {createClient} from "./clientConfig";
import {LoadFeaturesFromUrl} from "./services/Features";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

ReactDOM.createRoot(document.getElementById('root')).render(
    <LoadFeaturesFromUrl>
        <AuthProvider authConfig={authConfig}>
          <ClientProvider>
            <React.StrictMode>
              <RouterProvider router={routerConfig}/>
            </React.StrictMode>
          </ClientProvider>
        </AuthProvider>
    </LoadFeaturesFromUrl>
);

function ClientProvider({children}) {
  const {token} = useContext(AuthContext);

  return <ApolloProvider client={createClient(token)}>
    {children}
  </ApolloProvider>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
