import {useContext} from "react";
import {AuthContext} from "react-oauth2-code-pkce";

export function UnlessAuthenticated({children}) {
    const {token, loginInProgress} = useContext(AuthContext);
    if (loginInProgress || !token) return children;
}

export function WhenAuthenticated({children}) {
    const {token, loginInProgress} = useContext(AuthContext);
    if (!loginInProgress && token) return children;
}

export function WhenAuthorized({role, children}) {
    return isAuthorized(useContext(AuthContext), role) && children;
}

export function UnlessAuthorized({role, children}) {
    const {tokenData, loginInProgress} = useContext(AuthContext);
    if (!loginInProgress && tokenData && !hasRole(tokenData, role)) return children;
}

export function isAuthorized(authContext, role) {
    const {tokenData, loginInProgress} = authContext;
    return !loginInProgress && hasRole(tokenData, role);
}

function hasRole(tokenData, role) {
    return tokenData?.authorities?.find(r => r === `ROLE_${role}`);
}
