import React from "react";
import {cc, Heading, List, Subheading} from "./Typography";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {Timestamp} from "./Timestamp";
import {allUserOnboardingFields, ONBOARDING_ASSESSMENT_ID} from "./OnboardingAssessment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck, faEnvelopeCircleCheck} from "@fortawesome/pro-regular-svg-icons";
import {debug} from "../services/Debugger";
import {buttonStyle} from "../utils/styles";
import {Link} from "react-router-dom";

export const allUserFields = gql`
    ${allUserOnboardingFields}
    fragment AllUserFields on User {
        id
        superuser
        createdAt
        onboarding {
            ...AllUserOnboardingFields
        }
    }
`;

const GET = gql`
    ${allUserFields}
    query {
        userList {
            ...AllUserFields
        }
    }
`;

const followupOnboarding = gql`
    ${allUserFields}
    mutation RecordFollowUpUserOnboarding($id: String!) {
        recordFollowUpUserOnboarding(id: $id) {
            ...AllUserFields
        }
    }
`;

export function AdminTools({title, className}) {
    const {
        loading,
        error,
        data
    } = useQuery(GET);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    return <div className={className}>
        { title && <Heading>{title}</Heading> }
        <Subheading>All users</Subheading>
        <List>
            { data.userList.map(user => <User key={user.id} user={user} />) }
        </List>
    </div>
}

function User({user}) {
    return <div className="min-h-10">
        {user.id}{user.superuser && <> (superuser)</>},
        first logged in <Timestamp date={user.createdAt} />
        <Onboarding user={user} />
    </div>;
}

function Onboarding({user}) {
    const [recordFollowUp, {data, loading, error}] = useMutation(followupOnboarding);
    const pillClassName = "bg-soc-face p-2 ml-2 rounded-md";

    return <Link className="text-xs" to={`/admin/as/${user.id}/assessments/${ONBOARDING_ASSESSMENT_ID}/result`}>
        <Completed at={user.onboarding?.completedAt} />
        {user.onboarding?.completedAt && <FollowUp at={user.onboarding?.followedUpAt} />}
    </Link>;

    function Completed({at}) {
        if (at) return <> <span className={pillClassName}><FontAwesomeIcon icon={faBadgeCheck} /> Onboarded <Timestamp date={at} /></span></>;
        return <> <span className={pillClassName}>Onboarding in progress</span></>;
    }

    function FollowUp({at}) {
        if (loading) return <> <span className={pillClassName}>Submitting</span></>;
        if (error) return <> <span className={cc("bg-red-600 text-white", pillClassName)}>Submission error! {error.message}</span></>;
        if (at || data) return <>, <FontAwesomeIcon icon={faEnvelopeCircleCheck} /> Followed up on <Timestamp date={at || data.recordFollowUpUserOnboarding.onboarding.followedUpAt} /></>;
        return <> <button className={buttonStyle().className} onClick={
            () => recordFollowUp({variables: { id: user.id }})
                .then(() => {})
                .catch((reason) => debug(JSON.stringify(reason)))
        }>Follow Up</button></>;
    }
}
