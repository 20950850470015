import React, {useContext} from "react";
import {useQuery, gql} from "@apollo/client";
import {Timestamp} from "./Timestamp";
import {Error} from "./Error";
import {Center} from "./Center";
import {StatusCode} from "status-code-enum";
import {AuthContext} from "react-oauth2-code-pkce";
import {GraphQlError} from "./GraphQLError";
import {allBehaviourFields} from "./Behaviour";
import {allAssessmentFields, AssessmentDetails} from "./AssessmentDetails";

const GET_MODEL = gql`
    ${allAssessmentFields}
    query {
        assessmentList {
            ...AllAssessmentFields
        }
        
        users: userList {
            id
        }
        profile {
            id
        }
    }
`;


export function Model() {
    const { loading, error, data } = useQuery(GET_MODEL);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    const {assessmentList, ...remainingData} = data;
    const [assessment, _] = assessmentList;

    return <div>

        {/*<Assessment assessment={assessment} />*/}
        <pre className="text-xs">
            {JSON.stringify(data,null, 3)}
        </pre>
    </div>;
}
