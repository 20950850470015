import React from "react";
import {Link} from "react-router-dom";
import {cc} from "./Typography";
import {gql} from "@apollo/client";
import {Source} from "./Source";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleXmark} from "@fortawesome/pro-regular-svg-icons";

export const allBehaviourFields = gql`
  fragment AllBehaviourFields on Behaviour {
    id
    summary
    source
  }
`;

export const getBehaviourList = gql`
  ${allBehaviourFields}
  query {
    behaviourList {
      ...AllBehaviourFields
    }
  }
`;

export function Behaviour({behaviour, hideSource, poll, className}) {
  return <div className={cc(className, "flex border-2 rounded-md text-xl", poll === undefined ? "border-gray-200" : (poll ? "border-green-600" : "border-red-600"))}>
    <div className={cc("items-stretch w-8 text-white place-content-center flex", poll === undefined ? "bg-transparent" : (poll ? "bg-green-600" : "bg-red-600"))}>
      {poll === undefined ? "" : <FontAwesomeIcon icon={poll ? faCircleCheck : faCircleXmark} className="m-auto" />}
    </div>
    <div className="p-4">
      {behaviour.summary}
      {!hideSource && <Source wrapper={s => <div className="italic text-sm text-right pt-1">source: {s}</div>}>{behaviour.source}</Source>}
    </div>
  </div>;
}