import React from "react";
import {cc} from "./Typography";

/**
 * Use with "has-tooltip" className for parent that shows the tooltip when hovering over it.
 */
export function Tooltip({className, children}) {
    if (!children) return null;

    return <div className="tooltip max-w-screen-sm bottom-full left-1/3">
        <div className="rounded-md shadow p-2 bg-gray-200">
            {children}
        </div>
        <TriangleDown  className=""/>
    </div>;
}

function TriangleDown({className}) {
    return <div className={cc(className, "ml-4 w-0 h-0 border-l-transparent border-r-transparent border-l-8 border-r-8 border-t-gray-200 border-t-8")}></div>
}