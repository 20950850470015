import {cc} from "./Typography";
import React from "react";

export function ProgressBar({className, min, max, actual, potential,
                                height = "h-4",
                                hideValue = false,
                                bgClassName = "bg-gray-100",
                                scoreClassName = "bg-soc-cheeks-dark text-white",
                                potentialClassName = "bg-soc-face",
                                children
                            }) {
    let score = percentage(actual);
    return <div className={cc(className, height, bgClassName, "flex")}>
        {children}
        <span className={cc(scoreClassName, "h-full flex-none text-nowrap text-xs text-center")}
              style={{width: `${score}%`}}>{!hideValue && `${score.toFixed(0)} %`}</span>
        <div className={cc(potentialClassName, "h-full flex-none inline-block")}
             style={{width: `${percentage(potential - actual)}%`}}></div>
    </div>

    function percentage(value) {
        return 100 * (value - min) / (max - min);
    }
}