import {debug} from "./Debugger";
import {gql} from "@apollo/client";

const GET_RESULT_CACHE = gql`
    query ReadCachedAssessmentResults {
        cachedAssessmentResults
    }
`;

export function rememberAssessmentResults(client, ...results) {
    debug("Caching assessment results", results);

    client.cache.updateQuery({
        query: GET_RESULT_CACHE
    }, (data) => ({
        cachedAssessmentResults: [...new Set([...(data?.cachedAssessmentResults || []), ...(results.map(r => client.cache.identify(r)))])]
    }));
}

export function forgetAssessmentResults(client) {
    debug("Clearing cached assessment results");

    const data = client.cache.readQuery({
        query: GET_RESULT_CACHE
    });

    debug("Cached assessment results", data?.cachedAssessmentResults);
    (data?.cachedAssessmentResults || []).forEach(r => client.cache.evict(r));
    client.cache.gc();
}