import {Link, NavLink} from "react-router-dom";
import {Logo} from "./Logo";
import React, {Children, useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {cc} from "./Typography";
import {faAngleDown, faBars, faXmarkLarge} from "@fortawesome/pro-regular-svg-icons";
import {ofType} from "../utils/types";
import {isAuthorized} from "./Authorization.helper";
import {AuthContext} from "react-oauth2-code-pkce";

export function MainMenu({className, children}) {
    const authContext = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = React.useState(false);

    return <nav className={cc(className, "flex justify-between")}>
        <Link to="/">
            <Logo className="h-10"><span className="ml-3 font-title text-2xl content-center">Socrates</span></Logo>
        </Link>

        <ul className="hidden md:flex items-stretch bg-white font-medium rtl:space-x-reverse">
            {Children.map(children, (child, index) => confirmAuthorization(child, authContext) && createMenuItem(
                child,
                `nav-${index}`,
                "border-b-4 border-b-transparent hover:border-b-soc-cheeks has-[.active]:border-b-soc-cheeks-dark",
                <SubMenuWrapper />
            ))}
        </ul>

        <div className="md:hidden border-b-4 border-transparent">
            <ToggleMenuControl active={menuOpen} toggle={() => setMenuOpen(!menuOpen)} className="grow-0" />
            {/* anchor for dropdown in mobile view, the negative margin negates application frame margin */}
            <div className={cc(menuOpen ? "block" : "hidden", "relative -mr-3")}>
                <ul className="absolute w-screen top-0 right-0 flex flex-col bg-white font-medium mt-1 shadow divide-y">
                    {Children.map(children, (child, index) => confirmAuthorization(child, authContext) && createMenuItem(
                        child,
                        `nav-${index}`,
                        "border-l-8 min-w-full border-l-transparent hover:border-l-soc-cheeks has-[.active]:border-l-soc-cheeks-dark",
                        <MobileSubMenuWrapper />
                    ))}
                </ul>
            </div>
        </div>
    </nav>;
}

function confirmAuthorization(child, authContext) {
    if (child?.props?.role) return isAuthorized(authContext, child.props.role);
    return true;
}

export function SubMenu({image, name, children}) {}

function createMenuItem(item, key, className, subMenuWrapper) {
    if (ofType(item, <SubMenu />)) {
        if (!subMenuWrapper) throw "Nested submenus are not allowed";
        return React.cloneElement(subMenuWrapper, {key, keyPrefix: key, subMenu: item});
    }
    return <MenuItemWrapper key={key} className={className} spacing="px-6 py-2">{item}</MenuItemWrapper>;
}

function createSubMenuItems(items, keyPrefix) {
    return Children.map(items, (item, index) => createMenuItem(
        item,
        `${keyPrefix}-${index}`,
        "min-w-full border-l-8 border-l-transparent hover:border-l-soc-cheeks has-[.active]:border-l-soc-cheeks-dark"
    ));
}

function MenuItemWrapper({className, group, spacing, children}) {
    return <li className={cc(group && "group",
        className,
        "has-[.active]:font-bold")}
    >
        {wrap(children)}
    </li>;

    function wrap(item) {
        if (React.isValidElement(item) && ofType(item, <NavLink />)) {
            const itemChildren = item.props.children;
            return React.cloneElement(item, {}, <div className={spacing}>{itemChildren}</div>);
        }

        return <div className={spacing}>{item}</div>;
    }
}

function SubMenuWrapper({keyPrefix, subMenu}) {
    return <MenuItemWrapper group className="border-b-4 border-b-transparent hover:border-b-soc-cheeks has-[.active]:border-b-soc-cheeks-dark">
        <MenuControl image={subMenu.props.image} name={subMenu.props.name} />
        {/* element with no size as anchor for dropdown */}
        <div className="relative h-0 hidden group-hover:block">
            <ul className="absolute top-1 right-0 pr-2 bg-gray-100 min-w-60 shadow-md divide-y">
                {createSubMenuItems(subMenu.props.children, keyPrefix)}
            </ul>
        </div>
    </MenuItemWrapper>;

    function MenuControl({image, name}) {
        if (image)
            return <div>
                <img src={image} className="rounded h-10 w-10 inline-block"/>
                <FontAwesomeIcon icon={faAngleDown} className="ml-2"/>
            </div>;

        return <div className="px-6 py-2">
            {name}
            <FontAwesomeIcon icon={faAngleDown} className="ml-2"/>
        </div>;
    }
}

function MobileSubMenuWrapper({keyPrefix, subMenu}) {
    return <MenuItemWrapper group className="border-l-8 min-w-full border-l-transparent hover:border-l-soc-cheeks has-[.active]:border-l-soc-cheeks-dark">
        <MenuControl image={subMenu.props.image} name={subMenu.props.name} />
        <ul className="hidden group-hover:block bg-gray-100 ml-6 divide-y">
            {createSubMenuItems(subMenu.props.children, keyPrefix)}
        </ul>
    </MenuItemWrapper>;

    function MenuControl({image, name}) {
        if (image)
            return <div className="px-6">
                <FontAwesomeIcon icon={faAngleDown} className="mr-2"/>
                <img src={image} className="rounded h-10 w-10 inline-block mr-2"/>
                {name}
            </div>;

        return <div className="px-6 py-2">
            <FontAwesomeIcon icon={faAngleDown} className="mr-2"/>
            {image && <img src={image} className="rounded h-10 w-10 inline-block mr-2"/>}
            {name && name}
        </div>;
    }
}

function ToggleMenuControl({active, toggle, className}) {
    return <div>
        <button id="toggle" className={cc(className, "w-10 h-10 rounded-md hover:bg-soc-cheeks-dark md:hidden")}
                aria-controls="navbar-default"
                aria-expanded="false"
                onClick={toggle}>
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={active ? faXmarkLarge : faBars}/>
        </button>
    </div>;
}
