export function toReadableNumber(value, maximumDecimals = 2) {
    if (typeof value !== 'number') return value;

    if (!Number.isFinite(value)) return "?";
    if (Math.abs(value) > 1000000) return `${round(value / 1000000)}MM`;
    if (Math.abs(value) > 1000) return `${round(value / 1000)}K`;
    return round(value);

    function round(value) {
        return Number(value.toFixed(maximumDecimals));
    }
}