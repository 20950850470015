import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

export function createClient(accessToken) {
    const authLink = setContext((_, {headers}) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : "",
            }
        }
    });

    return new ApolloClient({
        link: authLink.concat(createHttpLink({
            uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
        })),
        cache: new InMemoryCache({

            typePolicies: {
                // Enable caching for the following types without 'id' field
                Capability: {
                    keyFields: ["assessment", ["id"], "name"]
                },
                AssessmentResult: {
                    keyFields: ["assessment", ["id"]],
                    fields: {
                        current: {
                            merge(existing= {}, incoming) {
                                return {...existing,  ...incoming};
                            }
                        }
                    }
                }
            }
        })
    });
}
